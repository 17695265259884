@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600;700&family=Noto+Serif+JP:wght@300;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  /* font-family: 'Noto Serif JP', serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 400;
}